import endpoints from "@/endpoints";
import axios from "axios";

export const fetchAccountData = async () => {
    try {
        const response = await axios.get(endpoints.account);
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const saveAccountName = async (email, username, firstName, lastName) => {
    try {
        const response = await axios.put(endpoints.account, {
            account: {
                email,
                username,
                firstName,
                lastName,
            },
        });
    } catch (e) {
        console.log("here");
        throw e;
    }
};

export const saveAccountEmail = async (email, username, firstName, lastName) => {
    try {
        const response = await axios.put(endpoints.account, {
            account: {
                email,
                username,
                firstName,
                lastName,
            },
        });
    } catch (e) {
        console.log("here");
        throw e;
    }
};

export async function saveNewPasswordForAccount(email, username, currentPassword, newPassword) {
    try {
        // Fetch token from localStorage or sessionStorage
        const token = (localStorage.getItem("token") || sessionStorage.getItem("token"))?.split("--")[0];

        // If no token is found, throw an error
        if (!token) {
            throw new Error("Authorization token not found. Please log in again.");
        }

        const response = await fetch(endpoints.account, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                account: {
                    email,
                    username,
                    oldPassword: currentPassword,
                    password: newPassword,
                },
            }),
        });

        // Handle 401 Unauthorized
        if (response.status === 401) {
            throw new Error("The current password you provided does not match our records.");
        }

        // Handle other error statuses
        if (!response.ok) {
            const errorMessage = await response.text(); // Get response as plain text
            throw new Error(errorMessage || "Something went wrong saving your password.");
        }

        // Handle responses with no body (e.g., 204 No Content)
        if (response.status === 204) {
            return null; // Return null for success without a body
        }

        // Parse and return JSON response
        return await response.json();
    } catch (error) {
        console.error("Error in saveNewPasswordForAccount:", error.message || error);
        throw error;
    }
}

